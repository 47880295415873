import { graphql } from 'gatsby';
import { string } from 'prop-types';
import React, { useEffect } from 'react';
import BEMHelper from 'react-bem-helper';
import { Content } from '../../common/content';
import './joblisting.scss';

const bem = new BEMHelper({
  name: 'content-joblisting',
});

export const Joblisting = ({
  id,
}) => {
  useEffect(() => {
    const addScript = (url) => {
      const script = document.createElement('script');
      script.src = encodeURI(url);
      script.async = true;
      document.body.appendChild(script);
    };
    addScript('https://medskin-suwelack.jobbase.io/widget/iframe.js');
  }, []);

  return (
    <Content id={id} {...bem()}>
      <div {...bem('widget')} id="psJobWidget" />
    </Content>
  );
};

Joblisting.propTypes = {
  id: string,
};

Joblisting.defaultProps = {
  id: null,
};

export const fragment = graphql`
  fragment JoblistingContentFragment on CraftGQL_pageContents_jobListing_BlockType {
    id
  }
`;
